@import "//at.alicdn.com/t/c/font_4518060_dnu5o07yu7t.css";

html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

/* 滚动条 */
::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 菜单滚动条 */
.ant-layout-sider-children {
  ::-webkit-scrollbar {
    width: 4px !important;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
  }
}

.ant-layout-sider-children:hover {
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
}

